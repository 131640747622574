import './bootstrap';

$(document).ready(function () {
    $('.navbar-toggler').click(function () {
        $('.fa-bars-staggered, .fa-xmark').toggleClass('d-none');
    });

    $('.js-select-with-search').select2();


    const numericInput = $("input[inputmode=numeric]");

    numericInput.on("input", function () {
        let inputValue = $(this).val().replace(/[^0-9]/g, "");

        const minValue = parseInt($(this).attr("min"));
        const maxValue = parseInt($(this).attr("max"));
        if (inputValue < minValue) {
            inputValue = minValue.toString();
        } else if (inputValue > maxValue) {
            inputValue = maxValue.toString();
        }

        $(this).val(inputValue);

        const maxLength = parseInt($(this).attr("maxlength"));
        if (inputValue.length > maxLength) {
            inputValue = inputValue.substring(0, maxLength);
            $(this).val(inputValue);
        }
    });

    $('.category_single').removeClass('d-none');
    $('.category_couple').addClass('d-none');

    if ($('#user_therapy_type_single').is(':checked')) {
        $('.category_single').removeClass('d-none');
        $('.category_couple').addClass('d-none');
        $('.category_couple input[type="checkbox"]').prop('checked', false);
    } else {
        $('.category_single').addClass('d-none');
        $('.category_couple').removeClass('d-none');
        $('.category_single input[type="checkbox"]').prop('checked', false);
    }

    $('#user_therapy_type_single').change(function () {
        if ($(this).is(':checked')) {
            $('.category_single').removeClass('d-none');
            $('.category_couple').addClass('d-none');
            $('.category_couple input[type="checkbox"]').prop('checked', false);
        }
    });

    $('#user_therapy_type_couple').change(function () {
        if ($(this).is(':checked')) {
            $('.category_single').addClass('d-none');
            $('.category_couple').removeClass('d-none');
            $('.category_single input[type="checkbox"]').prop('checked', false);

        }
    });

    $('#user_urgent_false').change(function () {
        if ($(this).is(':checked')) {
            $('.question-time-title__true').addClass('d-none');
            $('.question-time-title__nil').addClass('d-none');
            $('.question-time-title__false').removeClass('d-none');
            $('.question-time__nil').addClass('d-none');
        }
    });

    $('#user_urgent_true').change(function () {
        if ($(this).is(':checked')) {
            $('.question-time-title__false').addClass('d-none');
            $('.question-time-title__nil').addClass('d-none');
            $('.question-time-title__true').removeClass('d-none');
            $('.question-time__nil').addClass('d-none');
        }
    });

    $('#user_urgent_nil').change(function () {
        if ($(this).is(':checked')) {
            $('.question-time-title__false').addClass('d-none');
            $('.question-time-title__true').addClass('d-none');
            $('.question-time-title__nil').removeClass('d-none');
            $('.question-time__nil').removeClass('d-none');
        }
    });

    if ($('#user_urgent_false').is(':checked')) {
        $('.question-time-title__true').addClass('d-none');
        $('.question-time-title__nil').addClass('d-none');
        $('.question-time-title__false').removeClass('d-none');
        $('.question-time__nil').addClass('d-none');
    }
    if ($('#user_urgent_true').is(':checked')) {
        $('.question-time-title__false').addClass('d-none');
        $('.question-time-title__nil').addClass('d-none');
        $('.question-time-title__true').removeClass('d-none');
        $('.question-time__nil').addClass('d-none');
    }
    if ($('#user_urgent_nil').is(':checked')) {
        $('.question-time-title__false').addClass('d-none');
        $('.question-time-title__true').addClass('d-none');
        $('.question-time-title__nil').removeClass('d-none');
        $('.question-time__nil').removeClass('d-none');
    }

    $('.therapist-details__schedule input[name="slot"]').change(function () {
        $('.therapist-details__schedule__button button span').text('');
        const dataButtonValue = $(this).parent().find('.cs-slot-button').data('button');

        if ($(this).is(':checked')) {
            $('.therapist-details__schedule__button button').removeAttr('disabled');
            $('.therapist-details__schedule__button button span').text(dataButtonValue);

        } else {
            $('.therapist-details__schedule__button button').attr('disabled', true);
            $('.therapist-details__schedule__button button span').text('');

        }
    });

    $('.btn-navbar-mobile').click(function (e) {
        e.preventDefault()

        $('.mobile-menu-wrapper').toggleClass('show');

    })


});

