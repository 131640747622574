window.$ = window.jQuery = require('jquery');
window.Popper = require('@popperjs/core');

import 'bootstrap/dist/js/bootstrap.bundle';

import 'select2/dist/js/select2.min'

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
